<template>
  <div >    
   
          <div class="formBox" v-if="showForm">
              <span class="t">{{addForm.id?'编辑':'新增'}}管线</span>
              <el-divider></el-divider>
                  <el-form ref="addForm" class="searchForm"  :model="addForm" :rules="rules" label-width="80px">
                        <el-form-item label="管线名称" prop="lineName" size="mini">
                          <el-input v-model="addForm.lineName"  placeholder="请输入管线名称"></el-input>
                        </el-form-item>
                        <el-form-item label="管材"  size="mini">
                          <el-select v-model="addForm.styleWidth" placeholder="请选择" style="whith:300px" @change="tubingListChange">
                            <el-option
                              v-for="(item, index) in tubingList"
                              :key="index"
                              :label="item.name"
                              :value="item.id"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="管材型号"  size="mini">
                          <el-select v-model="addForm.material" placeholder="请选择" 
                            :disabled='!addForm.styleWidth'
                            @change="materialUpdate"
                          style="whith:300px">
                            <el-option
                              v-for="(item, index) in tubingVersion"
                              :key="index"
                              :label="item.name"
                              :value="item.id"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="公称直径"  size="mini">
                          <el-select v-model="addForm.diameter" placeholder="请选择"
                          :disabled='!addForm.styleWidth' style="whith:300px"
                          @change="diameterChange">
                            <el-option
                              v-for="(item, index) in diameterList"
                              :key="index"
                              :label="item.name"
                              :value="item.id"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="壁厚"  size="mini">
                          <el-input  name="limitLength" type="number" v-model="addForm.thickness" placeholder="请输入壁厚">
                            <template slot="append">mm</template>
                          </el-input>
                        </el-form-item>
                        <el-form-item label="实际内径"  size="mini">
                          <el-input  name="limitLength" type="number"  v-model="addForm.bore" placeholder="请输入内径">
                            <template slot="append">mm</template>
                          </el-input>
                        </el-form-item>
                        <el-form-item label="长度" prop="length" size="mini">
                          <el-input name="limitLength"  type="number" maxlength="15" v-model="addForm.length" disabled  placeholder="请输入设备长度">
                            <template slot="append">m</template>
                          </el-input>
                        </el-form-item>
                        <el-form-item label="压力级别"  size="mini">
                          <el-select v-model="addForm.stressLevel" placeholder="请选择" style="whith:100%">
                            <el-option
                              v-for="(item, index) in stressLevelList"
                              :key="index"
                              :label="item.name"
                              :value='item.dictCode'
                            ></el-option>
                          </el-select>
                        </el-form-item> 
                        <el-form-item label="燃气类型"  size="mini">
                          <el-select v-model="addForm.gasType" placeholder="请选择" style="whith:100%">
                            <el-option
                              v-for="(item, index) in gasTypeList"
                              :key="index"
                              :label="item.name"
                              :value="item.dictCode"
                            ></el-option>
                          </el-select>
                        </el-form-item> 
                        <el-form-item  label="建设日期"  size="mini">
                        
                          <el-date-picker
                            v-model="addForm.buildDate"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                          </el-date-picker>
                        </el-form-item>
                        <el-form-item label="敷设方式"  size="mini">
                          <el-select v-model="addForm.buriedType" placeholder="请选择" style="whith:100%">
                            <el-option
                              v-for="(item) in buriedTypeList"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item v-if="addForm.buriedType != '1'" label="埋深"  size="mini">
                          <el-input name="limitLength" type="number" maxlength="6"  v-model="addForm.burialDepth"  placeholder="请输入设备埋深">
                            <template slot="append">m</template>
                          </el-input>
                        </el-form-item>
                        <el-form-item label="状态"  size="mini">
                          <el-select v-model="addForm.useStatus" placeholder="请选择" style="whith:300px">
                            <el-option
                              v-for="(item, index) in useStatusList"
                              :key="index"
                              :label="item.name"
                              :value="item.dictCode"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="所属图层" prop="coverage" size="mini">
                          <!-- <el-select v-model="addForm.coverage" placeholder="请选择" style="whith:100%">
                            <el-option
                                v-for="(item) in buryMode"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            ></el-option>
                          </el-select> -->
                          <el-cascader
                            v-model="addForm.coverage"
                            :options="buryMode"
                            :props='{children: "children",
                              label: "name",
                              emitPath:false,
                              value:"id"
                              }'
                            :show-all-levels="false"
                            ></el-cascader>
                        </el-form-item>
                        <el-form-item label="颜色" prop="styleColor" size="mini">
                            <el-color-picker v-model="addForm.styleColor"></el-color-picker>
                        </el-form-item>  
                        <el-form-item label-width="0px" class="searchBt">
                          <el-button
                            size="mini"
                            type="primary"
                            class="searchBtn"
                            @click="save"
                            >保存</el-button
                          >
                          <el-button
                            size="mini"
                            class="searchBtn"
                            @click="closePDraw"
                            >取消</el-button
                          >
                        </el-form-item>
                  </el-form>
          </div>

  </div>
</template>

<script>

import {saveLine,selectDictListByParentCode } from '@/apis/commonType'
import { patrolGetDict,findDictTreeLine } from "@/RequestPort/maintenance";
import { selectDictListByPid } from "@/RequestPort/business/userFile";
import {serverMap} from "@/views/Gis/utils/dict"
import {findTreeMap } from "@/RequestPort/Inspection/inspePlan";
import { geoOws } from '@/apis/geo'
export default {
  name: 'formEdit',
  props:{
    showForm:{
      type:Boolean,
      default:false
    }, 
    addForm:{
      type:Object,
      default: function(){
        return {
          material:'',
          diameter:'',
          thickness:''
        }
      }
    },
    polyline1:{
      type:Object,
      default: null
    }
  },
  watch:{
    showForm(e){
      if(e == false){
        this.$emit('resetData')
      }
    },
  },
  data() {
    return {
      //  所选图层
      buryMode:[],
      
      crossMode:[
        '入钻造斜',
        '出钻造斜',
        '水平造斜',
      ],
      // showForm:false,
      name: '管线',
      rules: {
        lineName: [
          { required: true, message: '请输入管线名称', trigger: 'blur' },
          { min: 0, max: 100, message: '长度在 0 到 100 个字符', trigger: 'blur' }
        ],
        useStatus: [
          { required: true, message: '请选择使用状态', trigger: 'change' }
        ],
        // thickness: [
        //   { required: true, message: '请填写壁厚', trigger: 'blur' }
        // ],
        //  gasType: [
        //   { required: true, message: '请选择燃气类型', trigger: 'change' }
        // ],
         stressLevel: [
          { required: true, message: '请选择压力级别', trigger: 'change' }
        ],
        styleColor: [
          { required: true, message: '请选择颜色', trigger: 'change' }
        ],
        material:[
          { required: true, message: '请选择材质', trigger: 'change' }
        ],
        // buriedType:[
        //   { required: true, message: '请选择敷设方式', trigger: 'change' }
        // ],
        length:[
          { required: true, message: '请输入长度', trigger: 'blur' },
        ],
        bore:[
          { required: true, message: '请输入管径', trigger: 'blur' },
        ],
        buildDate:[
          { required: true, message: '请输入建设年度', trigger: 'change' }
        ],
        // coverage:[
        //   { required: true, message: '请选择所属图层', trigger: 'change' }
        // ],
        coverage:[
          { required: true, message: '请选择所属图层', trigger: 'change' }
        ],
      },
      // 使用状态
      useStatusList:[],
      // 燃气类型
      gasTypeList:[],
      // 压力级别
      stressLevelList:[],
      // 公称直径
      diameterList:[],
      // 管材
      tubingList:[],
      // 管材型号
      tubingVersion:[],
      // 材质
      materialList:[],
      // 埋设方式
      buriedTypeList:[],
      // nowYear:'',
      yearArray:[],
    }
  },
  computed: {},
   destroyed () {
    this.$eventBus.$off('setLocation') 
    this.$eventBus.$off('setLength') 
    // this.$eventBus.$off('showFormShow') 
   },
  mounted() {
    // this.addForm.ply = this.addForm.bore + (2*this.addForm.thickness)
    // setTimeout((e)=>{
    //   let inputArray = document.getElementsByClassName('el-input__inner')
    //   if(inputArray && (inputArray.length > 0)) {
    //     inputArray.forEach((item,index)=>{
    //       item.setAttribute('maxlength',10)
    //     })
    //   }
    // },1000)
    // console.log(this.addForm,99999999999)
    // this.$eventBus.$on('showFormShow', data => { 
    //   this.showForm = data
    // })
    let that = this
    // console.log(that.addForm,"jinlai ")
    // if(!that.addForm.bore) that.addForm.bore = 0
    // if(!that.addForm.thickness) that.addForm.thickness = 0
    // 所有字典
    findDictTreeLine({dictCode:'100023,100024,100028,100029,100004,30011'}).then(res => {
      // 管材
      this.tubingList = res.data['100023']
      // 敷设方式
      this.buriedTypeList = res.data['100004']
      /**
       * 获取树1 gis图层  2 巡线图层
       */
      findTreeMap({sysType:2}).then(res => {
        // console.log(res.data,111111)
        // res.data.forEach((el,index) => {
        //   // console.log(!el.children,el.id)
        //   console.log(index)
        //   if(!el.children){
        //     res.data.splice(index,1)
        //   }
        // })
        let buryModeList = res.data
        let arrs =  this.circulation(buryModeList)
        that.$set(that,'buryMode',arrs)
        that.$forceUpdate()
        if(that.addForm.styleWidth){
          that.$nextTick(() => {
            that.tubingListChangeHuixian(that.addForm.styleWidth)
          })
        }
      })
    })
    // 压力级别
    this.stressLevelList = serverMap[1006]
    // 燃气类型
    this.gasTypeList = serverMap[1008]
    // 管线状态
      this.useStatusList = serverMap[1007]
    this.$eventBus.$on('setLocation', data => { 
      this.setLocation(data)
    })
     this.$eventBus.$on('setLength', data => {
      let points=data.getPath()
      this.setLength(points)
    })
    
  },
  methods: {
    materialUpdate(){
      this.$forceUpdate()
    },
    // handleChange(){
      // this.addForm.coverage = this.addForm.value
    // },
    /**
     * 循环判断是图层组还是图层
     */
    circulation(val){
      val.forEach(item => {
          // 判断为图层组并没有子集
          if (item.layerType==1 && !item.children) {
            item.disabled = true
          } else if(item.children && item.children.length>0){
            // 有子集
            this.circulation(item.children)
          }
        })
        return val
    },
    /**
     *  选择管材
     */
    tubingListChange(val){
      this.addForm.material = ''
      this.addForm.diameter = ''
      this.addForm.thickness = ''
      this.addForm.bore = ''
      let that = this
      this.tubingList.forEach(el => {
        if(el.id == val){
          // 管材型号
          that.tubingVersion = el.children
          selectDictListByPid({pid:el.linkCode}).then(res => {
          // 公称直径
            that.diameterList = res.data
          })
        } 
      })
    },
    tubingListChangeHuixian(val){
      let that = this
      this.tubingList.forEach(el => {
        if(el.id == val){
          // 管材型号
          that.tubingVersion = el.children
          selectDictListByPid({pid:el.linkCode}).then(res => {
          // 公称直径
            that.diameterList = res.data
          })
        } 
      })
    },
    /**
     * 选择公称直径
     */
    diameterChange(val){
      this.$forceUpdate()
      // this.diameterList.forEach(el => {
      //   if(el.id == val){
      //     // 壁厚
      //     // this.addForm.thickness = el.value1.split('*')[1]
      //     this.$set(this.addForm,'thickness',el.value1.split('*')[1])
      //     this.$set(this.addForm,'ply',el.value1.split('*')[0])
      //     // 实际内径
      //     this.addForm.bore = Number(el.value1.split('*')[0])- 2*Number(el.value1.split('*')[1])
      //   }
      // })
    },
    /**
     * 填写完壁厚
     */
    // thickness(value){
    //   // 限制只能输入四位数
    //   if(value.length>4) this.addForm.thickness=value.slice(0,4)
    //   // 判断材质规格选择后才能计算
    //   if(this.addForm.ply) {
    //     // 计算内径 公称直径-2*壁厚
    //     this.addForm.bore = this.addForm.ply-(2*this.addForm.thickness)
    //   }
    // },
    setLength(point){
        let allLength = 0
        point.forEach((item,index)=>{
          if(index < point.length-1){
            allLength += this.getMapDistanceApi([item.lng,item.lat],[point[index+1].lng,point[index+1].lat])
          }
        })
      this.$parent.addForm.length = allLength.toFixed(2)
    },
    getMapDistanceApi(position1,position2){
      var lng1 = position1[0]
      var lat1 = position1[1]
      var lng2 = position2[0]
      var lat2 = position2[1]
      var radLat1 = lat1*Math.PI / 180.0;
      var radLat2 = lat2*Math.PI / 180.0;
      var a = radLat1 - radLat2;
      var  b = lng1*Math.PI / 180.0 - lng2*Math.PI / 180.0;
      var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a/2),2) +
          Math.cos(radLat1)*Math.cos(radLat2)*Math.pow(Math.sin(b/2),2)));
      s = s *6378.137 ;
      s = Math.round(s * 10000) / 10000;
      s = s * 1000
      return s;
    },
    resetFields(){
      if(this.$refs['addForm']){
        this.$refs['addForm'].resetFields()
      }
    },
    setLocation(polyline1){
      if(polyline1){
          this.addForm.locations=polyline1.getPath()
      }
    },
    /**
     * 保存
     */
    save(){
      this.$eventBus.$emit('vectorLineEdit')
      let that=this
      this.$nextTick(()=>{
         that.$refs['addForm'].validate(valid => {
          if (valid) {
               that.getNetwork()
          }
         })
      })
      
      
    },
    getNetwork(){
      let file=this.getfile(this.addForm.locations)
      let parames={
          service:'WFS',
          request:'GetFeature',
          typeName:'linqing:patrol_networks',
          maxFeatures:'50',
          outputFormat:'application/json',
          version:'1.0.0',
          filter:file
      }
      let parentNetId = this.$store.getters.GetPoly.id
      geoOws(parames).then(res => {
         if(res.features&&res.features[0]){
          let ids =[]
          res.features.forEach(el => {
            ids.push(el.properties.id)
          });
            this.addForm.networkId=ids.join(',')
          }
          this.saveApi()
        // if(!parentNetId){
        //   if(res.features&&res.features[0]){
        //     this.addForm.networkId=res.features[0].properties.id
        //     this.saveApi()
        //   }else{
        //     this.addForm.networkId= ''
        //     this.saveApi()
        //   }
        // }else if(res.features&&res.features[0]){
        //  this.addForm.networkId=res.features[0].properties.id
        //   if(parentNetId&&parentNetId==res.features[0].properties.id){
        //     this.saveApi()
        //   }else{
        //    return  this.$message.error('请画在片区内')
        //   }
        // }else{
        //   return this.$message.error('请画在片区内')
        // }
       }
      )
    },
    /**
     * 新增管线
     */
    saveApi(){
          let parames=this.addForm
          saveLine(parames).then(res => {
          if(res.code==200){
            this.$message.success('操作成功')
            this.$emit('save')
            
            this.$emit('GetNetworkDetailFun')
            this.$emit('getListArea')
          }
        })
    },
    getfile(lnglat){
      let fileStr=lnglat.join(' ')
      let file='<Filter xmlns:gml="http://www.opengis.net/gml">'+
                       
                        '  <Intersects>'+
                        ' <PropertyName>location</PropertyName>'+
                      '   <gml:LineString>'+
                            '<gml:coordinates>'+fileStr+'</gml:coordinates>'+
                        ' </gml:LineString>'+
                    '  </Intersects>'+
                '  </Filter>'
      return file
    },
    closePDraw(){
      this.$emit('closePDraw')
    }
  }
}
</script>

<style lang="less" scoped>

.searchForm{margin:15px 20px 0px;}
.searchBt{text-align: center;}
.formBox{position:absolute;left:10%;top:14px;background: #fff;width: 300px;padding-top:10px;z-index: 3;
.t{padding-left:10px;font-size: 16px;}
::v-deep{.el-divider--horizontal{margin-top:10px;margin-bottom:10px}
.el-date-editor.el-input, .el-date-editor.el-input__inner{width: 100%;}
}
.el-form-item{
      margin-bottom: 14px;
}
}
.el-select{
  display: inline;
}
.el-cascader, .el-tag{
  display: inline;
}
</style>
